.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card-footer {
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0);
  border-top: 0;
  padding: 10px 10px 0px 5px;
  border-top: solid 1px #dee2e6;
}

.icon-cell {
  padding-left: 35px !important;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: '선택';
}

.swal2-actions {
  display: flex !important; /* 중요 속성 추가 */
  justify-content: flex-end !important; /* 버튼을 오른쪽으로 정렬 */
  margin: 20px 10px 0px 0px;
}

.swal2-confirm {
  order: 2;
  margin-left: 5px;
}
.swal2-cancel {
  order: 1;
  color: #fff;
  /* border: 1px solid transparent; */
  border: 0;
  background: initial;
  border-color: #007bff;
  border-radius: 0.25rem;
  background-color: #6e7881 !important;
  /* background-color: rgb(255, 255, 255) !important; */
}

.nav-tabs .nav-link {
  color: #1f2d3d; /* 선택되지 않은 탭의 글자 색상 */
}

.nav-tabs .nav-link.active {
  background-color: #007bff; /* 선택된 탭의 배경색을 파란색으로 설정 */
  color: white; /* 텍스트 색상을 흰색으로 설정 (선택적으로) */
}

.nav-tabs {
  border-bottom: none; /* 기본 테두리 제거 */
  position: relative; /* 자식 요소에 대한 상대 위치 설정 */
}

.nav-tabs::after {
  content: '';
  position: absolute;
  bottom: 0; /* 탭의 아래쪽에 위치 */
  left: 0; /* 왼쪽 정렬 */
  width: 100%; /* 탭의 전체 너비에 맞춤 */
  height: 1px; /* 선의 두께 */
  background-color: #dee2e6; /* 선의 색상 */
  z-index: 1; /* 다른 요소보다 위에 표시 */
}

.nav-tabs .nav-item {
  display: inline-block; /* 각 탭을 인라인 블록으로 설정 */
}

.nav-tabs .nav-link {
  position: relative; /* 자식 요소에 대한 상대 위치 설정 */
  z-index: 2; /* 선보다 위에 표시 */
}

.react-datepicker-ignore-onclickoutside text {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: inset 0 0 0 transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker-ignore-onclickoutside:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* agent List CSS */

/* Agent Card 스타일 수정 */
.agent-card {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.agent-card:hover {
  transform: scale(1.03); /* 약간 확대 */
}

/* 이미지 자리 스타일 */
.img-placeholder {
  width: 80px;
  height: 80px;
  font-size: 48px;
  border: 1px solid #ccc;
}

/* 오버레이 */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgb(20 20 20 / 50%) !important;
}

.agent-card:hover .overlay {
  opacity: 1;
}

.overlay span {
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
}

/* agent List CSS */

.new-is-invalid {
  border-color: #dc3545;
  padding-right: 2.25rem !important;
  background-image: '';
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

/* 채팅 CSS */
.chat-enter {
  border-top: 1px solid #dee2e6;
  padding: 10px;
  display: flex;
  align-items: center;
}

.agent-box {
  max-width: 650px;
  position: relative;
}
/* 채팅 CSS */
